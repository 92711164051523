
import React, { useState } from "react";
import axios from 'axios';
import Alert from '@mui/material/Alert';
import Chip from '@mui/material/Chip';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import SvgIcon from '@mui/material/SvgIcon';
import {ReactComponent as GoogleCalendar} from './files/googleCalendar.svg';

export const Calendar = (props) => {
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [name, setName] = useState('');
    const [calendar, setCalendar] = useState('');
    const [notification, setNotification] = useState({});
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
  
    function handleEmail(e) {
        setEmail(e.target.value);
    }
    function handleName(e) {
        setName(e.target.value);
    }

    function handlePhone(e) {
        setPhone(e.target.value);
    }

    function selectCalendar(type) {
      setCalendar(type);
      if(type.toUpperCase() === 'GOOGLE') {
        window.location.href = 'https://calendar.google.com/calendar/u/0/share?slt=1Aeo0jCaDylvIGi6u_rouF4hY6HGIffOh6i2lMvCThfnwo-aPFiuQOak55lH4gxXBVejWLTDV5Dd7gA';
      } else {
        window.location.href = '/wedding-alex-geo.ics';
      }
    }

    async function save(e) { 
      e.preventDefault();
      if(email.length > 0 && phone.length > 0 && name.length > 0) {
        setIsSubmitting(true);
       await axios.post('/api/set-confirmation',{email:email,phone:phone,name:name}).then(res => {
        if(res?.data?.type && res?.data?.message) {
            setNotification(res?.data);
            setEmail('');
            setPhone('');
            setName('');
            setIsSubmitting(false);
            setTimeout(() => {
              setNotification({});
            }, 4000)
            if(res?.data?.type?.toUpperCase() !== 'error') {
              setIsSubmitted(true);
            }
          }
          
        }).catch(err => console.log(err))
      }
    }

  return (
    <header id="header">
      <div className="intro">
        <div className="overlay">
          <div className="container">
            <div className=" animation-fadeIn row justify-content-center align-content-center">
              <div className="col-md-8 col-md-offset-2 intro-text">
                <p className="beauty-script title">
                  {props.data ? props.data.title : "Loading"}
                  <span></span>
                </p>
                <p className="mb-3 cmu-script description">{props.data ? '- ' + props.data.paragraph + ' -' : "Loading"}</p>
               
              </div>
               <div className={"contactForm mt-max pt-3 pb-3 " + (isSubmitted ? 'mt-max-submitted': '')}>
                  {!isSubmitted &&   
                  <form className="mb-3 cmu-script d-flex flex-column justify-content-center align-items-center">
                  <p id="formDescr" className="cmu-script mb-4">Lasă-ne datele tale pentru a-ți transmite detaliile o dată ce le stabilim.</p>

                    <input type="text" required className=" mb-4 inputText"  placeholder="Nume" value={name} onChange={handleName} />
                    <input type="email" required className=" mb-4 inputText"  placeholder="E-mail" value={email} onChange={handleEmail} />
                    <input type="tel" required className=" mb-4 inputText"  placeholder="Telefon" value={phone} onChange={handlePhone} />
                 
                      <button type="submit" variant="contained" onClick={save} className="save-button">{!isSubmitting ? 'Trimite' : 
                      <div class="fa-3x"><i class="fas fa-spinner fa-spin"></i></div>}</button>
                  </form>}
                  {Object.keys(notification).length > 0 ? <Alert className="w-80 mb-3 mx-auto" severity={notification.type} onClose={() => {setNotification({})}}>{notification.message}</Alert>: null}
                  {isSubmitted && <div>
                    <p className=" mb-3 cmu-script">Salvează data în calendar</p>
                    <div id="saveCalendar">
                        <Chip onClick={() => selectCalendar('google')} className="calendarChip" sx={{color:"white", padding: "10"}} variant="outlined"  icon={<SvgIcon id="svgIcon" component={GoogleCalendar} viewBox="0 0 400 376.6" sx={{tramsform: 'scale(2)', fontSize: 100, width: 30, height: 30
                          }} ></SvgIcon>} label="Calendarul Google" />
                        <Chip onClick={() => selectCalendar('normal')} className="calendarChip" sx={{color:"white", padding: "10"}} variant="outlined" icon={<CalendarTodayIcon style={{ color: 'white' }}/>} label="Calendarul local"/>
                      </div>
                    </div>}
                </div>
                
            </div>
          </div>
        </div>
      </div>
    </header>

  );
};
