import axios from "axios"; // Import Axios

const getTimeRemaining = (endtime) => {
  let t = Date.parse(endtime) - Date.parse(new Date());
  let seconds = Math.floor((t / 1000) % 60);
  let minutes = Math.floor((t / 1000 / 60) % 60);
  let hours = Math.floor((t / (1000 * 60 * 60)) % 24);
  let days = Math.floor(t / (1000 * 60 * 60 * 24));
  return {
    total: t,
    days: days,
    hours: hours,
    minutes: minutes,
    seconds: seconds,
  };
};
export const updateClock = (timeinterval, endtime) => {
  const t = getTimeRemaining(endtime);
  if (t.total <= 0) {
    clearInterval(timeinterval);
  }
  return {
    days: t.days,
    hours: ("0" + t.hours).slice(-2),
    minutes: ("0" + t.minutes).slice(-2),
    seconds: ("0" + t.seconds).slice(-2),
  };
};

export const fetchCSVData = () => {
  const csvUrl =
    "https://docs.google.com/spreadsheets/d/e/2PACX-1vRmgwxpp59FGpUfZwR9omMgoonOSNGtWl4cq5GE-6GHQO8UJJvTVvoq4YQZZXwFyn-cz-_PgXsOu9ve/pub?output=csv"; // Replace with your Google Sheets CSV file URL

  axios
    .get(csvUrl) // Use Axios to fetch the CSV data
    .then((response) => {
      const parsedCsvData = parseCSV(response.data); // Parse the CSV data into an array of objects
      console.log(parsedCsvData); // Now you can work with 'csvData' in your component's state.
      return parsedCsvData;
    })
    .catch((error) => {
      console.error("Error fetching CSV data:", error);
    });
};

function parseCSV(csvText) {
  const rows = csvText.split(/\r?\n/); // Use a regular expression to split the CSV text into rows while handling '\r'
  const headers = rows[0].split(","); // Extract headers (assumes the first row is the header row)
  const data = []; // Initialize an array to store the parsed data
  for (let i = 1; i < rows.length; i++) {
    const rowData = rows[i].split(","); // Use the regular expression to split the row while handling '\r'
    const rowObject = {};
    for (let j = 0; j < headers.length; j++) {
      rowObject[headers[j]] = rowData[j];
    }
    data.push(rowObject);
  }
  return data;
}
