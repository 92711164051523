
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { updateClock } from "../utils/utils";

export const Home = (props) => {

  const [time, setTime] = useState({});
  useEffect(() => {
    let endtime = new Date(Date.parse(new Date(2025,5,8)) + 0 * 24 * 60 * 60 * 1000);
    let timeinterval = setInterval(
      () => setTime(updateClock(timeinterval, endtime)),
      1000
    );
    setTime(updateClock(timeinterval, endtime))
  }, []);

  return (
    <header id="header">
      <div className="intro">
        <div className="overlay">
          <div className="container">
            <div className="animation-fadeIn row justify-content-center align-content-center">
              <div className="col-md-8 col-md-offset-2 intro-text">
                <p className="beauty-script title">
                  {props.data ? props.data.title : "Loading"}
                  <span></span>
                </p>
                <p id="subTitle" className="cmu-script  description">{props.data ? '- '+ props.data.paragraph+ ' -': "Loading"}</p>
              </div>
              <div className="mt-max">
                  <p className="cmu-script  description pt-5 mb-3">8 iunie 2025</p>
                  <div className="description">
                  <i className="fa fa-map-marker geo-icon mr-2"></i>
                    <a target="_blank" id="locationLink" className="cmu-script " href="https://www.google.com/maps/place/Conacul+Heldsdorf/@45.7585328,25.5419925,17z/data=!3m1!4b1!4m6!3m5!1s0x40b357b944f170eb:0x1d122e2b51181812!8m2!3d45.7585291!4d25.5445674!16s%2Fg%2F11b5pkwx37?entry=ttu">Conacul Helsdorf, Brașov</a>
                  </div>
                  <Link to="/calendar" className="save-button cmu-script mt-5 mb-5">
                    <p>	Salvează data în calendar</p>
                  </Link>
                  <div id="clock" className="cmu-script  d-flex justify-content-between align-items-center mb-3 text-white">
                      <div className="time mr-2"><p>{time.days ?? 0} zile</p></div>
                      <div className="time mr-2"><p>{time.hours ?? 0} ore</p></div>
                      <div className="time mr-2"><p>{time.minutes ?? 0} minute</p></div>
                      <div className="time mr-2"><p>{time.seconds ?? 0} secunde</p></div>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
