import "./App.css";
import { Home } from "./components/Home";
import JsonData from "./data/data.json";
import { useEffect, useState } from "react";
import "bootstrap-icons/font/bootstrap-icons.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Calendar } from "./components/Calendar";
function App() {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);
  return (
    <div className="App">
      <Routes>
        <Route
          path="/calendar"
          element={<Calendar data={landingPageData.Header} />}
        />
        <Route path="/" element={<Home data={landingPageData.Header} />} />
      </Routes>
    </div>
  );
}

export default App;
